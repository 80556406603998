import * as React from "react"
import type { HeadFC } from "gatsby"
import Layout from "../components/layout"
import '../styles/global.css'

const pageStyles = {
    color: "#232129",
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
  }

const IndexPage = () => {
    return (
        <main className="bg-white" style={pageStyles}>
          <Layout>
        
    <div className="container mx-auto flex items-center">
    <div className="grid grid-cols-2  gap-2 mt-10">
      <div className=" overflow-hidden rounded-xl ">
        <img
          className="h-full w-full object-cover "
          src="/images/a1.jpeg"
        />
      </div>
      <div className=" overflow-hidden rounded-xl  ">
        <img
          className="h-full w-full object-cover  "
          src="/images/a2.jpeg"
        />
      </div>
      <div className=" overflow-hidden rounded-xl ">
        <img
          className="h-full w-full object-cover "
          src="/images/a3.jpeg"
        />
      </div>
      <div className=" overflow-hidden rounded-xl ">
        <img
          className="h-full w-full object-cover "
          src="/images/a4.jpeg"
        />
      </div>

      <div className=" overflow-hidden rounded-xl ">
        <img
          className="h-full w-full object-cover "
          src="/images/a5.jpeg"
        />
      </div>

        <div className=" overflow-hidden rounded-xl ">
        <img
          className="h-full w-full object-cover "
          src="/images/a6.jpeg"
        />
       </div>

      

    </div>
    </div>
    </Layout>
    </main>
    )
    }

export default IndexPage

export const Head: HeadFC = () => <title>El Amor Te Elige A Ti</title>;
